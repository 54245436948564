.page-plp {
  .intercom-lightweight-app-launcher,
  .intercom-messenger-frame + div {
    bottom: 20px !important;
  }

  .intercom-messenger-frame {
    bottom: 84px !important;
  }
}

.page-pdp {
  .intercom-lightweight-app-launcher,
  .intercom-messenger-frame + div {
    bottom: 150px !important;
  }
}

.overlay {
  z-index: 9999;
}
